export const idlFactory = ({ IDL }) => {
  const ProjectID = IDL.Text;
  const CanisterID = IDL.Principal;
  const TransactionType = IDL.Variant({
    'Fix' : IDL.Null,
    'Burn' : IDL.Null,
    'Offset' : IDL.Null,
  });
  const Time = IDL.Int;
  const Transaction = IDL.Record({
    'memo' : IDL.Vec(IDL.Nat8),
    'memoType' : IDL.Text,
    'cycles' : IDL.Int32,
    'canister' : CanisterID,
    'txType' : TransactionType,
    'datetime' : Time,
  });
  const CommonError = IDL.Variant({ 'Other' : IDL.Text });
  const Result_1 = IDL.Variant({ 'ok' : Transaction, 'err' : CommonError });
  const Result_3 = IDL.Variant({ 'ok' : IDL.Null, 'err' : CommonError });
  const CanisterMetadata = IDL.Record({ 'owner' : IDL.Principal });
  const ProjectState = IDL.Variant({
    'Active' : IDL.Null,
    'Archived' : IDL.Null,
    'Pending' : IDL.Null,
  });
  const ProjectMetadata = IDL.Record({
    'logo' : IDL.Vec(IDL.Nat8),
    'meta' : IDL.Vec(IDL.Nat8),
    'name' : IDL.Text,
    'state' : ProjectState,
  });
  const Result_2 = IDL.Variant({
    'ok' : IDL.Vec(Transaction),
    'err' : CommonError,
  });
  const Result = IDL.Variant({ 'ok' : ProjectMetadata, 'err' : CommonError });
  const icfootprint = IDL.Service({
    'addTransaction' : IDL.Func(
        [ProjectID, CanisterID, IDL.Int32, IDL.Text, IDL.Vec(IDL.Nat8)],
        [Result_1],
        [],
      ),
    'archiveProject' : IDL.Func([ProjectID], [Result_3], []),
    'deleteProject' : IDL.Func([ProjectID], [Result_3], []),
    'fixupTransaction' : IDL.Func(
        [ProjectID, CanisterID, IDL.Int32, IDL.Text, IDL.Vec(IDL.Nat8)],
        [Result_1],
        [],
      ),
    'getCanister' : IDL.Func(
        [IDL.Principal],
        [IDL.Opt(CanisterMetadata)],
        ['query'],
      ),
    'getProject' : IDL.Func([ProjectID], [IDL.Opt(ProjectMetadata)], ['query']),
    'getProjects' : IDL.Func([], [IDL.Vec(ProjectMetadata)], ['query']),
    'getTransactions' : IDL.Func([ProjectID], [Result_2], ['query']),
    'offsetTransaction' : IDL.Func(
        [ProjectID, CanisterID, IDL.Int32, IDL.Text, IDL.Vec(IDL.Nat8)],
        [Result_1],
        [],
      ),
    'registerProject' : IDL.Func(
        [ProjectID, IDL.Vec(IDL.Nat8), IDL.Vec(IDL.Nat8)],
        [Result],
        [],
      ),
    'setAdmin' : IDL.Func([IDL.Principal], [], []),
  });
  return icfootprint;
};
export const init = ({ IDL }) => { return []; };

export const idlFactory = ({ IDL }) => {
  const Time = IDL.Int;
  const Invoice = IDL.Record({
    'ref' : IDL.Nat64,
    'date' : Time,
    'cycles' : IDL.Nat64,
    'amount' : IDL.Float64,
    'project' : IDL.Text,
  });
  const CommonError = IDL.Variant({ 'Other' : IDL.Text });
  const Result = IDL.Variant({ 'ok' : Invoice, 'err' : CommonError });
  const Meta = IDL.Record({ 'admin' : IDL.Principal, 'version' : IDL.Float64 });
  const methodology = IDL.Service({
    'approveInvoice' : IDL.Func([IDL.Nat64], [Result], []),
    'generateInvoice' : IDL.Func(
        [IDL.Text, IDL.Float64, IDL.Nat64],
        [Result],
        [],
      ),
    'getApprovedInvoices' : IDL.Func([], [IDL.Vec(Invoice)], ['query']),
    'getInvoices' : IDL.Func([], [IDL.Vec(Invoice)], ['query']),
    'meta' : IDL.Func([], [Meta], ['query']),
  });
  return methodology;
};
export const init = ({ IDL }) => { return [IDL.Opt(IDL.Principal)]; };

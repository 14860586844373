import { idlFactory } from './bindings/icfootprint/icfootprint.did.js';
import { idlFactory as methodologyIdlFactory } from './bindings/methodology/methodology.did.js';
import { idlFactory as invoiceIdlFactory } from './bindings/invoice/invoice.did.js';
import { Actor, HttpAgent } from '@dfinity/agent';

// Fix this up to allow custom local endpoints.
const projectCanisterID = process.env.NODE_ENV === "production" ? "mbqeh-2aaaa-aaaal-aa3hq-cai" : "ryjl3-tyaaa-aaaaa-aaaba-cai";
const methodologyCanisterID = process.env.NODE_ENV === "production" ? "gw6nb-3aaaa-aaaal-aa5ca-cai" : "renrk-eyaaa-aaaaa-aaada-cai";
const invoiceCanisterID = process.env.NODE_ENV === "production" ? "ftkwk-giaaa-aaaal-aa5jq-cai" : "rkp4c-7iaaa-aaaaa-aaaca-cai";
const icEndpoint = process.env.NODE_ENV === "production" ? "https://boundary.ic0.app/" : "http://localhost:8000";
let anonymous_agent = new HttpAgent({
  host: icEndpoint,
});

// Fetch root key for certificate validation during development
if (process.env.NODE_ENV !== "production") {
  anonymous_agent.fetchRootKey().catch((err) => {
    console.warn("Unable to fetch root key. Check to ensure that your local replica is running");
    console.error(err);
  })
}

let icf_canister = Actor.createActor(idlFactory, {
  canisterId: projectCanisterID,
  agent: anonymous_agent,
})

let methodology_canister = Actor.createActor(methodologyIdlFactory, {
  canisterId: methodologyCanisterID,
  agent: anonymous_agent,
});

let invoice_canister = Actor.createActor(invoiceIdlFactory, {
  canisterId: invoiceCanisterID,
  agent: anonymous_agent,
});

async function getICFCanister() {
  return icf_canister;
}

async function getAuthorizedAgent() {
  // Check if authorized
  if (!await window.ic.plug.isConnected()) {
    await window?.ic?.plug?.requestConnect({
      whitelist: [projectCanisterID],
      host: icEndpoint,
    });
  } else {
    console.log('already connected!');
  }

  const success = await window.ic.plug.createAgent({
    whitelist: [projectCanisterID],
    host: icEndpoint,
  });

  if (!success) {
    console.log('what happened?...');
  }

  const actor = await window.ic.plug.createActor({
    canisterId: projectCanisterID,
    interfaceFactory: idlFactory,
    host: icEndpoint,
    // update host based on environment
  });
  return actor;
}

function massageProject(prj) {
  const state = Object.keys(prj.state);
  let logo = prj.name === "Crowdfund NFT" ? "/crowdfund.png" : "/icfootprint.svg"
  let parsed = {
    bio: prj.name === "ICFootprint" ? "Measure and offset your cycles on the Internet Computer." : "...",
  } // to be parsed from the metadata...
  return {
    background:"https://images.unsplash.com/photo-1520262494112-9fe481d36ec3?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3264&q=80",
    name: prj.name,
    id: prj.name,
    state: state[0],
    logo,
    bio: parsed.bio
  }
}

export async function getProjects() {
  const agent = await getICFCanister()
  let results = await agent.getProjects();
  return results.map((raw_project) => massageProject(raw_project))
}

export async function getProject(project_id) {
  const agent = await getICFCanister();
  let result;
  try {
    result = await agent.getProject(project_id)
  } catch (e) {
    console.log('we failed!!!')
    console.log(e)
  }
  if (result.length === 1) {
    return massageProject(result[0])
  } else {
    console.log('something went wrong...')
    console.log(result);
    throw "no project found";
  }
}

// NOTE: placeholder value until the methodology canister is integrated.
export async function getCanisterFootprint(canister) {
  return {
    value: 0.001 * Number(canister.cycles),
    metric: "kg",
  }
}

export async function registerCanister(project_id, meta) {
  const agent = await getICFCanister();

  const result = await agent.registerCanister(meta.id, meta.cycles);

  return result;
}

function massageTx(tx, opts) {
  const txType = Object.keys(tx.txType);
  return {
    canister: tx.canister.toText(),
    cycles: tx.cycles,
    date: new Date(Number(tx.datetime / 1000000n)),
    footprint: `${opts.coefficient * tx.cycles} kg`,
    raw_footprint: opts.coefficient * tx.cycles,
    memo: "...",
    memoType: ".../...",
    status: txType[0],
    verify: "WHATQNR",
  }
}

export async function getTransactions(project_id) {

  const agent = await getICFCanister();
  const opts = {
    coefficient: await methodology_canister.getCoefficient(),
  }

  const result = await agent.getTransactions(project_id);
  if (result.ok) {
    const x = result.ok.map((tx) => massageTx(tx, opts));
    return x;
  } else {
    throw result.err;
  }
}

export async function addTransaction(tx) {
  const agent = await getAuthorizedAgent();
  let result = await agent.addTransaction(
    tx.project_name,
    tx.canister,
    tx.cycles,
    tx.memoType,
    tx.memo
  )
  return result;
}

export function getCanisterIDs(transactions) {
  let canisters = {};

  transactions.forEach((tx) => {
    canisters[tx.canister] = true;
  })

  return Object.keys(canisters);
}

export function cumulativeTransactions(transactions) {
  let accumulated_cycles = 0;
  return transactions.reduce((acc, tx) => {
    accumulated_cycles += tx.cycles

    acc.push({
      ...tx,
      ...{
        cycles: accumulated_cycles,
      }
    })
    return acc;
  }, [])
}

export function getOffsetAmount(transactions) {
  return transactions.reduce((acc, tx) => {
    return acc + tx.raw_footprint;
  }, 0);
}

export async function initiateOffsetPayment(tx) {
  // Generate Invoice {Reference no, amount, Cycles, Date, Recvr}
  const maybeInvoice = await invoice_canister.generateInvoice(tx.project, tx.amount, tx.cycles);
  if (maybeInvoice.err) {
    throw maybeInvoice.err
  }
  const invoice = maybeInvoice.ok;
  const transfer = {
    to: "lxrei-wd5iw-avmq4-xvscj-mbfc6-3vjub-nztji-3c2pa-tz7su-bmyzt-uae",
    amount: invoice.amount * 100_000_000, // transfer to gwei.
    opts: {
      memo: `${invoice.ref}`,
    },
  }
  console.log(transfer);
  try {

    const result = await window.ic?.plug?.requestTransfer(transfer);
    console.log(result)

    const transferStatus = transfer?.transactions?.transactions[0]?.status;
    if (transferStatus === 'COMPLETED') {
      alert("Successfully offset!")
    } else {
      console.log(transferStatus);
    }
  } catch (e) {
    console.log('transfer failed!');
    console.log(e);
  }
}